<template>
    <div class="container">
      <div ref="barChart" class="barChart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    data() {
        return {
            propList: [],
            sortedArr: [],
        };
    },
    props: ["data"],
    created() {
        this.sortData(this.data);
    },
    mounted() {
        this.adjustChartHeight(); 
        this.renderChart();
    },
    methods: {
        sortData(dataArr) {
            if (dataArr) {
                this.sortedArr = dataArr.sort((a, b) => a.sumVal - b.sumVal);
      


                if (this.sortedArr.length > 10) {
                    this.sortedArr = this.sortedArr.slice(0, 10);
                }

                this.propList = this.sortedArr.map(item => item.text);
            }
        },
        adjustChartHeight() {
            const itemHeight = 40;  
            const maxVisibleItems = 10; 
            const chartHeight = Math.min(this.sortedArr.length * itemHeight, maxVisibleItems * itemHeight);
            this.$refs.barChart.style.height = `${chartHeight}px`;
            this.$refs.barChart.style.maxHeight = '400px'; 
        },
        renderChart() {
            const chart = echarts.init(this.$refs.barChart);

            const option = {
                grid: {
                    left: '15%',
                    right: '15%',
                    bottom: '10%',
                    top: '10%',
                    containLabel: true
                },
                tooltip: {
                    formatter: (params) => {
                        return `${params.data.value} ${params.data.unit} ${params.data.ratio} %`;
                    },
                    textStyle: {
                        fontSize: 18,
                        fontWeight: 'bold',
                    },
                },
                legend: {},
                yAxis: {
                    type: 'category',
                    data: this.propList,
                    axisLabel: {
                        fontSize: 15,
                        show: false 
                    },
                   
                },
                xAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: this.propList,
                        data: this.sortedArr.map(item => ({
                            value: item.sumVal,
                            unit: item.sumUnit,
                            ratio: item.sumRatio
                        })),
                        type: 'bar',
                        colorBy: 'data',
                        // barCategoryGap: '20%',  
                       barWidth: '100%',
                        cursor: "auto",
                        label: {
                            show: true,
                            position: 'insideLeft',
                            rich: {
                                bold: {
                                    fontWeight: 'bold',
                                },
                                unit: {
                                    color: 'white',
                                },
                            },
                            formatter: (params) => {
                                if (params.data.unit) {
                                    return `{bold|${params.name}} {unit| ${params.data.value} ${params.data.unit}}`;
                                } else {
                                    return `{bold|${params.name}} {unit| ${params.data.value}}`;
                                }
                            },
                            emphasis: {
                                focus: 'self'
                            }
                        }
                    }
                ]
            };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        }
    }
};
</script>

<style scoped>
.container {
    width: 100%;
}
.barChart {
    width: 100%;
    min-height: 110px;
}
</style>
