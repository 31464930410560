<template>
    <div style="height: 100%; width: 100%;">
        <div ref="dailyHeatMap" style="width: 100%; height: 100%;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    props: ["dailyData"],
    data() {
        return {};
    },
    watch: {
        dailyData() {
            this.renderChart();
        }
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            const chart = echarts.init(this.$refs.dailyHeatMap);

            const weeks = ['1주차', '2주차', '3주차', '4주차', '5주차'];

            const option = {
                tooltip: {
                    position: 'top',
                    formatter: function (params) {
                        return `<div style="font-size: 14px;">
                                    ${params.marker} 
                                    <span>${params.value[0]}</span>: 
                                    <span style="font-weight: bold;">${params.value[1]} kWh</span>
                                </div> `;
                    },
                },
                visualMap: [
                    {
                        show: true,
                        min: 0,
                        max: this.dailyData.statsDesc.max,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: 5
                    },
                ],
                calendar: [
                    {
                        orient: 'vertical',
                        yearLabel: {
                            show: false,
                            margin: 50
                        },
                        monthLabel: {
                            show: false,
                            nameMap: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                            margin: 20
                        },
                        dayLabel: {
                            margin: 10,
                            firstDay: 0,
                            nameMap: ['일', '월', '화', '수', '목', '금', '토']
                        },
                        cellSize: ['auto', 40],
                        top: '10%',
                        left: 40,
                        height: '70%',
                        width: '80%',
                        // left: 60,
                        // right: 60,
                        range: this.dailyData.data[0][0].slice(0, 7),
                    }
                ],
                series: [
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        // data: this.getVirtualData(String(new Date().getFullYear()))
                        data: this.dailyData.data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    },
                ],
                graphic: weeks.map((week, index) => ({
                    type: 'text',
                    left: 5,
                    top: 60 + index * 53,  // 주차당 Y좌표를 조정
                    style: {
                        text: week,
                        font: '11px sans-serif',
                        fill: '#333'
                    }
                }))
            };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        },
    }
};
</script>