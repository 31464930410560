<template>
    <v-chart v-if="!Array.isArray(data)" class="gauge-chart" :options="option" 
        style="border: 1px solid #ececec; border-radius: 10px; box-shadow: 3px 3px 3px #ececec;" />
</template>

<style scoped>
.gauge-chart {
    height: 100%;
    width: 100%;
}
</style>

<script>
export default {
    props: ["data"],
    data() {
        return {
            option: {
                series: [
                    {
                        type: 'gauge',
                        center: ['50%', '60%'],
                        radius: '80%',
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: this.getMaxValue(this.data.sumVal),
                        splitNumber: 10,
                        itemStyle: {
                            color: '#58D9F9',
                            shadowColor: 'rgba(0,138,255,0.45)',
                            shadowBlur: 10,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2
                        },
                        progress: {
                            show: true,
                            roundCap: true,
                            width: 12,
                            itemStyle: {
                                color: '#EF5350'
                            }
                        },
                        pointer: {
                            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                            length: '75%',
                            width: 16,
                            offsetCenter: [0, '5%'],
                            itemStyle: {
                                color: '#EF5350'
                            }
                        },
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 12
                            }
                        },
                        axisTick: {
                            distance: 4,
                            length: 2,
                            splitNumber: 2,
                            lineStyle: {
                                width: 1,
                                color: '#999'
                            }
                        },
                        splitLine: {
                            distance: 4,
                            length: 8,
                            lineStyle: {
                                width: 2,
                                color: '#999'
                            }
                        },
                        axisLabel: {
                            distance: 20,
                            color: '#999',
                            fontSize: 10,
                            formatter: function (value) {
                                if (value >= 1000000) value = value / 1000000 + 'M';
                                else if (value >= 1000) value = value / 1000 + 'K';
                                return value;
                            },
                        },
                        title: {
                            offsetCenter: [0, '30%'],
                            fontSize: 14,
                        },
                        detail: {
                            offsetCenter: [0, '45%'],
                            formatter: function (value) {
                                return '{value|' + value + '}{unit|kWh}';
                            },
                            rich: {
                                value: {
                                    fontSize: 14,
                                    fontWeight: 'bolder',
                                    color: '#777'
                                },
                                unit: {
                                    fontSize: 12,
                                    color: '#999',
                                    padding: [0, 0, 0, 10]
                                }
                            }
                        },
                        data: [
                            {
                                name: this.data.text,
                                value: this.data.sumVal
                            }
                        ],
                        animationType: 'scale',
                        animationEasing: 'elasticOut',
                        animationDelay: function () {
                            return Math.random() * 500;
                        }
                    }
                ]
            }
        };
    },
    mounted() {
        // 창 크기 변경 시 차트 리사이즈
        window.addEventListener('resize', this.resizeChart);
    },
    beforeDestroy() {
        // 이벤트 리스너 해제
        window.removeEventListener('resize', this.resizeChart);
    },
    methods: {
        getMaxValue(value) {
            let max;
            if (value <= 1) return 1;
            max = Math.pow(10, Math.ceil(Math.log10(value)));
            return max;
        },
        resizeChart() {
            this.$refs.chart && this.$refs.chart.resize();
        }
    }
};
</script>

