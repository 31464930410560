<template>
    <v-chart class="doughtnut-chart" :options="option" 
        style="border: 1px solid #ececec; border-radius: 10px; box-shadow: 3px 3px 3px #ececec;" />
</template>

<script>

export default {
    props: ["data"],
    data() {
        return {
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `${params.name} : ${params.value} ${params.data.unit} (${params.percent}%)`
                    }
                },
                series: [
                    {
                        // name: '비율',
                        type: 'pie',
                        radius: ['30%', '60%'],
                        avoidLabelOverlap: false,
                        padAngle: 2,
                        itemStyle: {
                            borderRadius: 4,
                        },
                        center: ['50%', '50%'],
                        data: this.data.sort(function (a, b) {
                            return a.value - b.value;
                        }),
                        // roseType: 'radius',
                        labelLine: {
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        },
                        animationType: 'scale',
                        animationEasing: 'elasticOut',
                        animationDelay: function () {
                            return Math.random() * 200;
                        },
                    }
                ]
            }
        }
    },
}

</script>

<style scoped>
.doughtnut-chart {
    height: 100%;
    width: 100%;
}
</style>