<template>
  <div v-if="baseChartData" class="row" style="height: 100%;">
    <!-- 라인 차트 컨테이너 -->
    <div ref="lineChart" class="col-9" style="width: 75%; height: 100%;"></div>
    <!-- 파이 차트 컨테이너 -->
    <div ref="pieChart" class="col-3" style="width: 25%; height: 100%;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: ["unit", "baseChartData"],
  data() {
    return {
      lineChart: null,
      pieChart: null,
      selectedYearIndex: 0,
    };
  },
  computed: {
    dataValidationFunc() {
      return this.baseChartData && this.baseChartData.length > 1;
    },
    labels() {
      return this.baseChartData[0]?.data?.labels || [];
    },
    seriesData() {
      return this.baseChartData
        .filter((_, index) => index !== 0)
        .map((chartData) => chartData.data.datasets[0].data);
    },
    productNames() {
      return this.baseChartData
        .filter((_, index) => index !== 0)
        .map((chartData) => chartData.data.datasets[0].label);
    },
    pieData() {
      return this.productNames.map((name, index) => ({
        name: name,
        value: this.seriesData[index][this.selectedYearIndex]
      }));
    }
  },
  mounted() {
    if (this.dataValidationFunc) {
      this.initCharts();

      // Resize 이벤트 리스너 등록
      window.addEventListener('resize', this.resizeCharts);
    }
  },
  watch: {
    baseChartData() {
      this.dataValidationFunc && this.initCharts();
    }
  },
  methods: {
    initCharts() {
      if (this.lineChart) {
        this.lineChart.dispose();
      }
      if (this.pieChart) {
        this.pieChart.dispose();
      }


      this.lineChart = echarts.init(this.$refs.lineChart);
      const lineOption = {
        legend: {
          bottom: 5
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          formatter: function (params) {
            let tooltipContent = `<div style="font-size: 12px; width: 230px;">${params[0].axisValueLabel}<br/>`;

            params.forEach((param) => {
              // 데이터가 null 또는 0인 경우 제외
              const value = param.data[param.componentIndex + 1];
              if (value !== null && value !== 0) {
                tooltipContent += `
                  <div style="display: flex; justify-content: space-between;">
                    <div>${param.marker}${param.seriesName}</div> 
                    <span style="font-weight: bold;"> ${value} </span>
                  </div>`;
              }
            });

            tooltipContent += `</div>`;
            return tooltipContent;
          }
        },
        dataset: {
          source: [
            ['product', ...this.labels],
            ...this.productNames.map((name, index) => [name, ...this.seriesData[index]])
          ]
        },
        xAxis: {
          type: 'category',
          axisPointer: {
            show: true,
            type: 'line', // 세로 선을 표시 (접선)
            label: {
              show: true // 축에 대한 정보를 보여줌
            }
          }
        },
        yAxis: {
          type: 'value',
          // name: this.unit,
          // nameTextStyle: {
          //   align: 'right',
          //   padding: [0, 10, 0, 0],
          // },
          axisLabel: {
            // padding: [0, 10, 0, 0],
            formatter: `{value} ${this.unit ? this.unit : this.baseChartData[0].data.datasets[0].unit}`, // Y축 라벨 값 포맷
          },
          gridIndex: 0
        },
        // grid: { top: '10%', right: '10%', bottom: '15%', left: '5%' },
        grid: { left: 70, right: 70 },
        series: this.productNames.map(() => ({
          type: 'line',
          smooth: true,
          seriesLayoutBy: 'row',
          stack: 'Total',
          areaStyle: {},
          emphasis: { focus: 'series' },
          symbolSize: 3
        }))
      };

      // 'click' 이벤트 리스너 
      // this.lineChart.on('click', (event) => {
      //   if (event.componentType === 'series' && event.seriesType === 'line') {
      //     const dimension = event.dataIndex; // 클릭한 데이터의 인덱스를 가져옵니다.
      //     this.selectedYearIndex = dimension;
      //     this.updatePieChart(); // 클릭한 데이터에 맞춰 파이 차트 업데이트합니다.
      //   }
      // });

      // axisPointer 이벤트 처리
      this.lineChart.on('updateAxisPointer', (event) => {

        if (!event.axesInfo[0]) return;

        const dimension = event.axesInfo[0].value; // 현재 마우스가 위치한 x축 인덱스를 가져옵니다.

        // 모든 시리즈에서 해당 인덱스의 값이 null인지 확인
        const allNull = this.seriesData.every((data) => data[dimension] === null);
        if (allNull) {
          return; // 데이터가 모두 null이면 선택하지 않음
        }

        if (this.selectedYearIndex === dimension) {
          return;
        }


        this.selectedYearIndex = dimension;
        this.updatePieChart(); // 클릭한 데이터에 맞춰 파이 차트 업데이트합니다.
        this.selectedYearIndex = dimension;
      });

      this.lineChart.on('mouseout', () => {
        // 마우스가 차트를 벗어났을 때는 previousSelectedIndex 값을 그대로 유지
        // 아무 처리도 하지 않음으로써 마지막 유효한 선택 유지
      });

      this.lineChart.setOption(lineOption);

      // 파이 차트 초기화합니다.
      this.pieChart = echarts.init(this.$refs.pieChart);
      this.updatePieChart();

      window.addEventListener('resize', () => {
        this.lineChart.resize();
        this.pieChart.resize();
      });
    },

    updatePieChart() {
      // 선택된 인덱스에 따라 파이 차트를 업데이트합니다.
      const pieOption = {
        title: {},
        tooltip: {
          trigger: 'item',
          // formatter: '{b}\u00A0\u00A0\u00A0\u00A0<span style="font-weight: bold;"> {c} ({d}%)</span>'
          formatter: function (params) {
            // let tooltipContent = `<div style="font-size: 12px; width: 230px;">${params[0].axisValueLabel}<br/>`;

            // params.forEach((param) => {
            //   // 데이터가 null 또는 0인 경우 제외
            //   const value = param.data[param.componentIndex + 1];
            //   if (value !== null && value !== 0) {
            //     tooltipContent += `
            //       <div style="display: flex; justify-content: space-between;">
            //         <div>${param.marker}${param.seriesName}</div> 
            //         <span style="font-weight: bold;"> ${value} </span>
            //       </div>`;
            //   }
            // });

            // tooltipContent += `</div>`;
            // return tooltipContent;

            // made by woonsik 240925
            let tooltipContent = ``;
            const value = params.data.value;
            if (value !== null && value !== 0) {
              tooltipContent += `
                  <div style="font-size: 12px; width: 230px; display: flex; justify-content: space-between;">
                    <div>${params.marker}${params.data.name}</div> 
                    <span style="font-weight: bold;"> ${value} </span>
                  </div>
                </div>
              `;
            }
            return tooltipContent;
          }
        },

        legend: {
          show: false
        },
        series: [
          {
            name: '제품',
            type: 'pie',
            radius: ['20%', '90%'],
            center: ['50%', '50%'],
            data: this.pieData,
            // emphasis: {
            //   focus: 'self'
            // },
            emphasis: {
              itemStyle: {
                shadowBlur: 20, // 그림자의 흐림 정도
                shadowOffsetX: 0, // 그림자의 X축 오프셋
                shadowOffsetY: 0, // 그림자의 Y축 오프셋
                shadowColor: 'rgba(0, 0, 0, 0.5)' // 그림자의 색상
              }
            },
            itemStyle: {
              shadowBlur: 10, // 기본 그림자의 흐림 정도
              shadowOffsetX: 0, // 기본 그림자의 X축 오프셋
              shadowOffsetY: 0, // 기본 그림자의 Y축 오프셋
              shadowColor: 'rgba(0, 0, 0, 0.3)' // 기본 그림자의 색상
            },
            label: {
              show: true,
              position: 'inside',
              formatter: function (params) {
                if (params.percent < 10) {
                  return ''; // 비율이 10% 미만일 경우 빈 문자열 반환
                }

                // 이 부분에서 color 속성을 동적으로 설정
                let val = params.percent.toFixed(0);
                return `${params.name}\n(${val}%)`;
              },
              color: '#EEE', // 기본값
              fontSize: 12,
            }
          }
        ]
      };

      this.pieChart.setOption(pieOption);
    }
  }
};
</script>

<style scoped>
/* .col-3{
  margin-top: 30px;
} */
</style>
