<template>
    <div class="sankeyContainer">
        <div ref="sankeyChart" style="width: 100%; height: 700px;"></div>
        <b-modal ref="childNodeInfoModal" centered size="lg" @hidden="closeChildNodeInfoModal">
            <template #modal-header>
                <h5>부하 구성 목록</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary" style="flex: 1;"
                        @click="closeChildNodeInfoModal">닫기</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    props: ["data", "phscPointUsageMap"],
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

        this.renderChart();
    },
    computed: {
        isExpanded() {
            return this.expanded;
        }
    },
    watch: {
        data() {
            this.renderChart();
        },
    },
    methods: {
        closeChildNodeInfoModal() {
            this.$ref.childNodeInfoModal.hide();
        },
        onNodeSelect(item) {
            console.log(item)
            this.$ref.childNodeInfoModal.show();
        },
        /** flow차트에서 물리관제점이 아닌 타겟일경우 해당 함수를 통해 공통 코드 관리에서  대체 코드 문자열을 입력합니다.*/
        findReplaceablePoint(item) {
            const replacePoint = this.phscPointUsageMap.get(item);
            return replacePoint ? replacePoint : item;
        },
        renderChart() {
            let chart = echarts.init(this.$refs.sankeyChart);


            let option = {
                title: {
                    text: ''
                },
                // grid:{
                //  bottom:"50px"   
                // },
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                    formatter: function (params) {
                        if (params.dataType === 'edge') {
                            return `${this.findReplaceablePoint(params.data.source)} -> ${this.findReplaceablePoint(params.data.target)} <span style="font-weight: bold;"> ${Number(params.data.value).toFixed(3)} ${params.data.unit}</span>`;
                        } else if (params.dataType === 'node') {
                        
                            return `${this.findReplaceablePoint(params.name)}<br/><span style="font-weight: bold;">\n ${Number(params.value).toFixed(3)} kWh</span>`;
                        }
                    }.bind(this)
                },
               series: [
    {
        type: 'sankey',
        data: this.data.data,
        links: this.data.links,
        emphasis: {
            focus: 'adjacency',
        },
        draggable: true,
        left: 'center',
        top: 'middle',
        layout: 'none',
        width: '85%',
        height: '95%',
        label: {
            show: true,
            formatter: function (params) {
                const value = Number(params.value);
                if (value != 0)
                    return `{name|${this.findReplaceablePoint(params.name)}}\n{value|${Number(params.value).toFixed(3)} kWh}`;
                else
                    return '';
            }.bind(this),
            rich: {
                name: {
                    fontWeight: 'bold',
                    color: '#444', 
                    fontSize: 12 ,
                    lineHeight: 20 
                },
                value: {
                    fontWeight: 500, 
                    color: '#888', 
                    fontSize: 12 
                }
            }
        },
        lineStyle: {
            color: 'gradient',
            curveness: 0.1
        }
    }
]
            };

            chart.setOption(option);
            chart.on('click', (params) => {
                if (params.dataType === 'node') {
                    this.onNodeSelect(params);
                    //   this.modalTitle = `노드: ${this.findReplaceablePoint(params.name)}`;
                    //   this.modalContent = `값: ${Number(params.value).toFixed(3)}`;
                    //   this.showModal = true;
                }
            });
            window.addEventListener("resize", () => {
                chart.resize();
            });
        }
    }
}

</script>

<style>
.sankeyContainer {
    margin-top: 50px;
}


</style>

